import * as React from 'react';
import { v4 as generateUuid } from 'uuid';

import Seo from '../components/seo';
import TextField from '../components/form/textField';
import { ChangeEvent, useCallback, useState } from 'react';
import { validateEmail, validateRequired } from '../components/form/validation';
import axios from 'axios';
import PersistError from '../components/persistError';
import Loader from '../components/loader';
import { Link } from 'gatsby';

const API_PATH = '/api/persist';

interface IState {
  firstname: string;
  lastname: string;
  street: string;
  email: string;
}

export function validate(state: IState): string | null {
  return (
    validateRequired(state.firstname) ||
    validateRequired(state.lastname) ||
    validateRequired(state.street) ||
    validateEmail(state.email)
  );
}

const IndexPage = () => {
  const [stage, setStage] = useState<string>('closed'); // initial
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [state, setState] = useState<IState>({
    firstname: '',
    lastname: '',
    street: '',
    email: '',
  });

  const handleFormSubmit = useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();
      setShowValidationErrors(true);

      if (validate(state)) {
        return;
      }

      setStage('loading');

      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          uuid: generateUuid(),
          ...state,
        },
      })
        .then(() => {
          setStage('success');
        })
        .catch(() => {
          setStage('error');
        });
    },
    [state, setStage, setShowValidationErrors],
  );

  return (
    <div className="container mx-auto py-6 px-4">
      <Seo
        title="Anmeldung zum Dorfflohmarkt | moments FOTOGRAFIE"
        description="Home"
      />
      {stage === 'loading' && <Loader text="Kleinen Augenblick..." />}
      {stage === 'error' && <PersistError />}
      {stage === 'initial' && (
        <>
          <br />
          <div className="prose">
            <h1>Hallo,</h1>
            <p>
              schön dass du dich für den Dorfflohmarkt am{' '}
              <strong>15.05.2022 von 12.00-16.00 Uhr</strong> in Pfaffenweiler
              anmelden willst!
            </p>
            <p>
              Für eine Teilnahme registrier dich ganz einfach, indem du unten
              deine Kontaktdaten angibst.
            </p>
            <p>
              Bis zum 08.05.2022 kannst du dich auch jederzeit selbstständig
              wieder abmelden.
            </p>
            <p>
              Alle relevanten Fragen haben wir für Dich im{' '}
              <Link
                className="hover:scale-105 ease-in-out duration-200 text-teal-600"
                to="/faq"
                target="_blank"
              >
                FAQ
              </Link>{' '}
              zusammengefasst und beantwortet.
            </p>
          </div>
          <br />
          <br />
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Vorname"
              value={state.firstname}
              onChange={firstname => {
                setState(state => ({ ...state, firstname }));
              }}
              error={
                showValidationErrors ? validateRequired(state.firstname) : null
              }
            />
            <TextField
              label="Nachname"
              value={state.lastname}
              onChange={lastname => {
                setState(state => ({ ...state, lastname }));
              }}
              error={
                showValidationErrors ? validateRequired(state.lastname) : null
              }
            />
            <TextField
              label="Straße und Hausnummer"
              value={state.street}
              onChange={street => {
                setState(state => ({ ...state, street }));
              }}
              error={
                showValidationErrors ? validateRequired(state.street) : null
              }
            />
            <TextField
              label="E-Mail"
              value={state.email}
              onChange={email => {
                setState(state => ({ ...state, email }));
              }}
              error={showValidationErrors ? validateEmail(state.email) : null}
            />
            <button className="px-6 py-2 rounded border bg-teal-600 text-white hover:bg-neutral-100 hover:text-neutral-800 transition duration-300">
              Senden
            </button>
          </form>
          <br />
          <br />
          <br />
          <div className="prose">
            <p className="font-light">
              Der Dorfflohmarkt ist eine private Veranstaltung auf deinem
              Privatgelände. Die Organisatorinnen übernehmen weder eine
              Verkaufsgarantie noch sonstige Haftung.
            </p>
          </div>
          <br />
        </>
      )}
      {stage === 'success' && (
        <div className="prose">
          <h2>Du hast die Anmeldung erfolgreich abgeschlossen.</h2>
          <p>Bitte schaue in dein E-Mail Postfach.</p>

          <p>
            Du hast eine Bestätigungsmail bekommen mit einem Link. Über diesen
            kannst du dich bis zum 08.05.2022 wieder abmelden, solltest du doch
            nicht teilnehmen können.
          </p>
        </div>
      )}
      {stage === 'closed' && (
        <div className="prose">
          <h2>Anmeldung zum Dorfflohmarkt ist geschlossen!</h2>
        </div>
      )}
    </div>
  );
};

export default IndexPage;
