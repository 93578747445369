const emailRegEx =
  // eslint-disable-next-line no-control-regex
  /(?:[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;
const REQUIRED_ERROR = 'Bitte ausfüllen';
const INVALID_EMAIL = 'Gib eine gültige E-Mail-Adresse ein';

export const validateRequired = (value: string): string | null => {
  if (!value || value.trim().length === 0) {
    return REQUIRED_ERROR;
  }

  return null;
};

export function validateEmail(email: string): string | null {
  if (!email || email.trim().length === 0) {
    return REQUIRED_ERROR;
  }

  if (!emailRegEx.test(email)) {
    return INVALID_EMAIL;
  }

  return null;
}
