import * as React from 'react';

interface OwnProps {
  error?: string | null;
}

const ValidationError = ({ error }: OwnProps) => {
  if (!error) {
    return null;
  }
  return <p className="mt-1 text-red-600 text-sm">{error}</p>;
};

export default ValidationError;
