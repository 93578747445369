import * as React from 'react';
import { ChangeEvent, useCallback, useState } from 'react';
import ValidationError from './validationError';

interface OwnProps {
  name?: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  error?: string | null;
  multiline?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
}

const TextField = ({
  name,
  label,
  value,
  onChange,
  error,
  multiline = false,
  disabled = false,
  autoFocus,
}: OwnProps) => {
  const [rows, setRows] = useState(3);
  // ToDo autoFocus not working with framer motion

  const handleChangeTextArea = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const textareaLineHeight = 24;
      const minRows = 3;
      const maxRows = 10;

      const previousRows = event.target.rows;
      event.target.rows = minRows; // reset number of rows in textarea

      const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

      if (currentRows === previousRows) {
        event.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }

      onChange(event.target.value);
      setRows(currentRows < maxRows ? currentRows : maxRows);
    },
    [rows],
  );

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [],
  );

  const props = {
    className:
      'py-1 px-2 border border-neutral-200 resize-none focus:outline-none focus:border-teal-600',
    name,
    value,
    disabled,
    autoFocus,
  };

  return (
    <label className="flex flex-col mt-6 mb-3">
      {label}
      {multiline ? (
        <textarea rows={rows} onChange={handleChangeTextArea} {...props} />
      ) : (
        <input type="text" onChange={handleChangeInput} {...props} />
      )}
      <ValidationError error={error} />
    </label>
  );
};

export default TextField;
